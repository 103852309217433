import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'components/Theme/Context';

type Props = {
  value: any;
  label?: string;
  example?: string;
  onChange?: Function;
  style?: object;
  disabled?: boolean;
  password?: boolean;
  id1?: string;
  containerStyle?: any;
};

function TextInput2({
  value,
  label,
  example,
  onChange,
  style,
  disabled,
  password,
  id1,
  containerStyle,
}: Props) {
  const [id, setId] = useState<string>();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!id1) {
      import('uuid').then((uuid) => {
        setId(uuid.v1());
      });
    } else {
      setId(id1);
    }
  }, [id1]);

  return (
    <div style={{ ...containerStyle }}>
      <div className="ml-2 mt-2 text-xs text-gray-400 uppercase">{label}</div>
      {disabled && (
        <input
          className="relative bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-80 bg-down-arrow bg-no-repeat bg-bottom-4"
          id={id}
          placeholder={example || label}
          autoComplete={password ? 'password' : label?.split(' ').join('-')}
          type={password ? 'password' : 'text'}
          value={value}
          style={style}
          disabled
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
        />
      )}

      {!disabled && (
        <input
          className="relative pt-2 bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-80 bg-down-arrow bg-no-repeat bg-bottom-4"
          id={id}
          placeholder={example || label}
          value={value}
          type={password ? 'password' : 'text'}
          style={style}
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
        />
      )}
    </div>
  );
}

export default TextInput2;
