import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect, useRef } from 'react';

import { LanguageContext } from 'components/Language/Context';
import NavMenu from './Menu';
import Menu from '../Menu';
import ProfilePopover from 'components/UI5/ProfilePopover';
import { useApi } from 'utils/api';

import grenadier from 'assets/grenadier.svg';
import ineos from 'assets/ineos.svg';

const Container = styled.div`
  width: 100%;
`;

// todo update logo
const Top = styled.div`
  width: 100%;
  background-color: #00142c;
  background-image: url(https://media.ineosgrenadier.com/wp-content/themes/ineos/assets/img/bg/header-patern.png);
  background-position: -60px 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding: 12px;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 90px;
  margin-left: 12px;
`;

const Bottom = styled.div`
  padding: 12px 0 12px 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: white;
  height: 50px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const TitleLogo = styled.img`
  height: 12px;
  margin-left: 12px;
`;

const MenuWrapperTop = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  font-family: mmcregular;

  span {
    color: white;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 12px;
    text-decoration: none;
    font-weight: 700;
    display: block;
  }
  span.nav-active {
    border-bottom: 1px solid ${(props) => props.$defaults?.fontcolour};
  }
`;

const MenuWrapper = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  font-family: mmcregular;

  span {
    color: black;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 16px;
    text-decoration: none;
    font-weight: 700;
    display: block;
  }
  span.nav-active {
    border-bottom: 2px solid #b61515;
  }

  @media (max-width: 900px) {
    position: relative;
    left: -30px;
  }
`;

const LoginMenuWrapper = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  font-family: mmcregular;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 0.75em;

  span {
    color: black;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 16px;
    text-decoration: none;
    font-weight: 700;
    display: block;
  }
  span.nav-active {
    border-bottom: 1px solid ${(props) => props.$defaults?.fontcolour};
  }
`;

const HanburgerWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #b61515;
  span {
    color: white;
  }
`;

const Error = styled.div`
  width: 100%;
  text-align: center;
  background: red;
  color: white;
  padding: 12px;
`;

const BackButton = styled.button`
  float: right;
  display: flex;
  position: relative;
  top: -3px;
  width: 5rem;

  @media (max-width: 900px) {
    position: absolute;
    right: 10px;
    top: 1px;
  }
`;

export default function IneosHeader() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { messages } = useContext(LanguageContext);
  const { isError: errors } = useApi();
  // const authUser: any = { email: 'bob@email.com' };
  const userName = localStorage.getItem('fullName');

  useEffect(() => {
    const fav: any = document.getElementById('favicon');
    if (fav) fav.href = 'https://ineosgrenadier.com/dist/Grenadier/favicon.ico';
  }, []);

  const toggleMenu = () => setShowMenu(!showMenu);
  const profilePopoverRef = useRef<any>(null);

  const handleOpenProfilePopover = (e) => {
    // profilePopoverRef.current.showAt(e.target);
  };

  return (
    <Container id="x-header">
      {errors && errors?.length > 0 && (
        <Error>
          {errors.map((x: any) => x.split('error::')[1]).join(', ')}
        </Error>
      )}
      <Top>
        {/* <Logo src="https://ineosautomotive.stylelabs.cloud/api/public/content/5905763ec39d49d88e4e6c95d6bba767" /> */}
        <Logo src={ineos} />
        <MenuWrapperTop className="flex justify-end w-full items-center pr-3">
          <div className="flex" style={{ whiteSpace: 'nowrap' }}>
            <span className="text-center text-xxs font-normal font-sans mr-10">
              <a href="https://ineosgrenadier.com/en/au/#">
                {messages.grenadierKit}
              </a>
            </span>
            <span className="text-center text-xxs font-normal font-sans mr-10">
              <a href="https://media.ineosgrenadier.com/en/?lang=en">
                {messages.press}
              </a>
            </span>
            <span className="text-center text-xxs font-normal font-sans mr-10">
              <a href="https://ineosgrenadier.com/en/au/careers">
                {messages.cariers}
              </a>
            </span>
            <span className="text-center text-xxs font-normal font-sans">
              <a href="https://ineosgrenadier.com/en/au/contact-us">
                {messages.contact}
              </a>
            </span>
          </div>
        </MenuWrapperTop>
      </Top>
      <Bottom>
        {/* <TitleLogo src="https://mss-p-034-delivery.stylelabs.cloud/api/public/content/df5c4d721e0d4949be45e4120ff496bc?v=7658595e" /> */}
        <TitleLogo src={grenadier} />
        <LoginMenuWrapper className="flex justify-end w-full items-center pr-6">
          {!userName && (
            <div className="flex" style={{ whiteSpace: 'nowrap' }}>
              <span className="text-center text-xxs font-normal font-sans mr-10">
                <Link to="/">{messages.home}</Link>
              </span>
              <span className="text-center text-xxs font-normal font-sans">
                <Link to="/login">{messages.login}</Link>
              </span>
            </div>
          )}
        </LoginMenuWrapper>
        <MenuWrapper className="flex justify-start w-full items-center pr-3 pl-10">
          {userName && <NavMenu noHamburger />}
        </MenuWrapper>
        {userName && (
          <BackButton
            className="p-3 m4"
            style={{}}
            onClick={() => {
              window.history.back();
            }}
          >
            <span
              className="fas fa-angle-left"
              style={{ color: 'darkgrey', fontSize: '1.5rem' }}
            />
            {'   '}
            <span
              style={{
                paddingLeft: '0.5rem',
              }}
            >
              Back
            </span>
          </BackButton>
        )}
        {/* {userName && (
          <HanburgerWrapper>
            <button
              className="p-4 pt-3"
              onClick={(e) => handleOpenProfilePopover(e)}
            >
              <span className="fas fa-user" />
            </button>
          </HanburgerWrapper>
        )}
        {userName && (
          <Menu
            setShowMenu={toggleMenu}
            showMenu={showMenu}
            messages={messages}
          />
        )} */}
      </Bottom>
      {/* <ProfilePopover popoverRef={profilePopoverRef} /> */}
    </Container>
  );
}
