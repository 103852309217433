import { lazy, Suspense, useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// import {
//   ShellBar,
//   Button,
//   Avatar,
//   AvatarSize,
//   AvatarColorScheme,
// } from '@ui5/webcomponents-react';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import { Device } from '@ui5/webcomponents-react-base';
import '@ui5/webcomponents-icons/dist/AllIcons.js';

import * as S from './styled';
import CustomLogin from 'components/UI5/Login';
import Footer from 'components/Footer';
import Header from 'components/Header';
// import NotificationsToastContainer from 'components/UI5/NotificationsToastContainer';
import ProfilePopover from 'components/UI5/ProfilePopover';
import ResetPassword from '../login/screens/ResetPassword';
import SideNavigation from 'components/UI5/SideNavigation';
import { ThemeContext } from 'components/Theme/Context';
import { VSSUser } from 'types';
import { useApi } from 'utils/api';
import { useLanguages, useWebSocket, useInterval } from 'utils/hooks';
import PageTitle from './components/PageTitle';
import ShellBar from './components/ShellBar';
import FSLoader from './components/FSLoader';

const Login = lazy(() => import('../login'));
const Demo = lazy(() => import('../demo'));

const Home = lazy(() => import('../home'));
const Leads = lazy(() => import('../leads'));
const LeadsDetail = lazy(() => import('../leads/detail'));
const Prospects = lazy(() => import('../prospects'));
const ProspectDetail = lazy(() => import('../prospects/detail'));
const Customers = lazy(() => import('../customers'));
const CustomerDetail = lazy(() => import('../customers/detail'));
const Orders = lazy(() => import('../salesOrders'));
const Quotations = lazy(() => import('../quotations'));
const VehicleOrderCreate = lazy(() => import('../salesOrders/vehicles/create'));
const VehicleQuotationCreate = lazy(
  () => import('../quotations/vehicles/create')
);
const InfoCenter = lazy(() => import('../infoCenter'));
const InfoCenterCreate = lazy(() => import('../infoCenter/create'));
const Terms = lazy(() => import('../terms'));
const Privacy = lazy(() => import('../privacy'));
const PXValuation = lazy(() => import('../pxvaluation'));
const SupportTicketHome = lazy(() => import('../ticketing'));
const SupportTicketSubmit = lazy(
  () => import('../ticketing/components/CreateUpdate')
);
const SupportTicketList = lazy(
  () => import('../ticketing/components/MyTickets')
);
const DemoLog = lazy(() => import('../demoLog'));
const DemoLogDetails = lazy(() => import('../demoLog/details'));
const Statements = lazy(() => import('../statements'));
const StatementsDetails = lazy(() => import('../statements/details'));

const _pages = ['', ''];

const Redirecter = ({ doLogout, user }: { doLogout: Function; user: any }) => {
  // const { user } = useLoggedIn(true, true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // console.log('slugs ::::', user?.email, slugs, storedClient, client, data);
  }, []);

  return <span />;
};

const Page = styled.div<{ $lang: any }>`
  span[title='${(props) => props.$lang?.actions}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.netValue}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.grossValue}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.orderDate}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.orderNumber}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.phone}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.mobile}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.orderStatus}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.expiryDate}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.msgType}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.department}'] {
    text-align: center;
  }
  span[title='${(props) => props.$lang?.category}'] {
    text-align: center;
  }
`;

const NoUser = styled.div`
  width: 100%;
  padding-top: 5rem;
  text-align: center;
  color: black;
  font-size: 20px;
`;

// for DEMO purposes only
const getFullnameCookie = () => {
  const fName = localStorage.getItem('fullName');

  if (fName) document.cookie = 'FULLNAME=' + fName;

  return fName || null;
};

let notificationsCurrent: any[] = [];
let notificationsNew: any[] = [];

let User: VSSUser = {};
export default function App() {
  const { theme } = useContext(ThemeContext);
  const [resetState, setResetState] = useState<any>();
  const { messages: lang } = useLanguages();
  const { call, callUpdate, isLoading, _status } = useApi();
  const [user, setUser] = useState<VSSUser | null>(null);
  const [showLogin, setShowLogin] = useState(getFullnameCookie() === null);
  const [userInitials, setUserInitials] = useState('');

  // const [notificationsBadge, setNotificationsBadge] = useState('');
  // const [notifications, setNotifications] = useState<any>([]);
  // const [showNotificationToasts, setShowNotificationToasts] = useState(false);
  // const [notificationToasts, setNotificationToasts] = useState<any>([]);

  useEffect(() => {
    // TODO: check why this suddenly does not work
    // for now, theme is set in the index.html file as stated in the documentation
    // https://sap.github.io/ui5-webcomponents/playground/customizing/theme/
    setTheme('Grenadier');
  }, []);

  // const [xuser, setXUser] = useState<any>();

  // useEffect(() => {
  //   if (!User) return
  //   const f = async () => {
  //     const u = await call('userDetailsFetch');
  //     console.log({ u });
  //   };
  //   f();
  // }, [call, User]);

  const showCockpit = true;
  // window.location.hostname.includes('localhost') ||
  // window.location.hostname.includes('musing');

  const [xuser, setXUser] = useState<any>();

  const getCurrentUser = async () => {
    User = await call('userFetch');
    const XUser = await call('userDetailsFetch', User?.Id);
    setXUser(XUser);
    console.log({ User, XUser });
    localStorage.setItem('xname', XUser?.Name);
    localStorage.setItem('countryKey', XUser?.Country);
    if (!User) {
      // setShowLogin(true);
      localStorage.removeItem('fullName');
      // window.location.reload();
    }
    // User.Authorisations.HaveChineseDealerRole = true;
    User.HaveChineseDealerRole = User.SpecialCountryRole === 'CN';
    User.HaveItalyDealerRole = User.SpecialCountryRole === 'IT';
    console.log('USER ::::', User);
    setUser(User);
    console.log('Below is the user details');
    console.log(User);
    const x = XUser?.Name?.split(' ');
    if (x) setUserInitials(`${x[x.length - 2][0]}${x[x.length - 1][0]}`);
  };

  useEffect(() => {
    if (!showLogin) {
      getCurrentUser();
    }
    // eslint-disable-next-line
  }, [showLogin]);

  // useWebSocket(getNotifications);
  // Notifications handling - END

  return (
    <Page $lang={lang}>
      <Router>
        <PageTitle />
        {showCockpit && <Header />}

        {user?.Id && (
          <ShellBar
            userInitials={userInitials}
            showLogin={showLogin}
            User={User}
            xuser={xuser}
          />
        )}

        {showLogin && (
          <CustomLogin setShowLogin={setShowLogin} setUser={setUser} />
        )}

        {Object.values(_status).some((x) => x === 'loading') && <FSLoader />}
        {resetState?.accessToken ? (
          <ResetPassword
            accessToken={resetState.accessToken}
            resetSuccess={() => setResetState(undefined)}
          />
        ) : !user?.Id ? (
          <NoUser>{user !== null ? lang?.noUser : ''}</NoUser>
        ) : (
          <>
            {/* <NotificationsToastContainer
              show={showNotificationToasts}
              items={notificationToasts}
            /> */}

            <div id="x-content" style={{ overflow: 'auto' }}>
              {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
              {/* {true && <Redirecter doLogout={doLogout} user={user} />} */}
              <Suspense
                fallback={
                  <S.Container theme={theme}>
                    <S.Loader>
                      <FormattedMessage id="loading" />
                      ...
                    </S.Loader>
                  </S.Container>
                }
              >
                <Switch>
                  <Route exact path="/home">
                    <Home authorisations={User?.Authorisations} user={User} />
                  </Route>

                  <Route exact path="/leads">
                    <Leads user={User} authorisations={User?.Authorisations} />
                  </Route>
                  <Route exact path="/leads/:mode">
                    <LeadsDetail
                      currentUser={User}
                      authorisations={User?.Authorisations}
                    />
                  </Route>
                  <Route exact path="/leads/:mode/:id/:statuses">
                    <LeadsDetail
                      currentUser={User}
                      authorisations={User?.Authorisations}
                    />
                  </Route>

                  <Route exact path="/leads/update/:id">
                    <LeadsDetail
                      currentUser={User}
                      authorisations={User?.Authorisations}
                    />
                  </Route>

                  <Route exact path="/supportTicket/submit">
                    <SupportTicketSubmit dealer={xuser} user={User} />
                  </Route>
                  <Route exact path="/supportTicket/submit/:id">
                    <SupportTicketSubmit dealer={User} user={User} />
                  </Route>
                  <Route exact path="/supportTicket/list">
                    <SupportTicketList
                      user={User}
                      authorisations={User?.Authorisations}
                    />
                  </Route>
                  <Route exact path="/supportTicket">
                    <SupportTicketHome />
                  </Route>

                  {User?.Authorisations?.SalesCockpitVhclSOSalesAgentGet && [
                    <Route exact path="/demoLog">
                      <DemoLog authorisations={User?.Authorisations} />
                    </Route>,
                    <Route exact path="/demoLog/:mode">
                      <DemoLogDetails authorisations={User?.Authorisations} />
                    </Route>,
                    <Route exact path="/demoLog/:mode/:id/:vin">
                      <DemoLogDetails authorisations={User?.Authorisations} />
                    </Route>,
                  ]}

                  <Route exact path="/prospects">
                    <Prospects authorisations={User?.Authorisations} />
                  </Route>
                  <Route exact path="/prospects/:mode">
                    <ProspectDetail
                      authorisations={User?.Authorisations}
                      userprop={user}
                    />
                  </Route>
                  <Route exact path="/prospects/:mode/:id">
                    <ProspectDetail
                      authorisations={User?.Authorisations}
                      userprop={user}
                    />
                  </Route>

                  <Route exact path="/customers">
                    <Customers
                      authorisations={User?.Authorisations}
                      userprop={user}
                    />
                  </Route>
                  <Route exact path="/customers/:mode">
                    <CustomerDetail
                      userprop={user}
                      authorisations={User?.Authorisations}
                    />
                  </Route>
                  <Route exact path="/customers/:mode/:id">
                    <CustomerDetail
                      userprop={user}
                      authorisations={User?.Authorisations}
                    />
                  </Route>

                  <Route
                    exact
                    path="/salesOrders/:type/:id"
                    component={VehicleOrderCreate}
                  >
                    <VehicleOrderCreate
                      user={user}
                      auths={User?.Authorisations}
                    />
                  </Route>
                  <Route
                    exact
                    path="/quotations/:type/:id"
                    component={VehicleQuotationCreate}
                  >
                    <VehicleQuotationCreate user={user} />
                  </Route>
                  <Route exact path="/salesOrders">
                    <Orders type="all" user={user} />
                  </Route>
                  <Route exact path="/quotations">
                    <Quotations type="all" user={user} />
                  </Route>
                  <Route exact path="/salesOrders/reservations">
                    <Orders type="reservations" user={user} />
                  </Route>
                  <Route exact path="/salesOrders/vehicles">
                    <Orders type="vehicles" user={user} />
                  </Route>

                  {(User?.Authorisations?.SalesCockpitVhclSODealerUpdate ||
                    !!User?.HaveChineseDealerRole) && [
                    <Route exact path="/salesOrders/customerOrders">
                      <Orders type="customerOrders" user={user} />
                    </Route>,
                    <Route exact path="/salesOrders/stockOrders">
                      <Orders type="stockOrders" user={user} />
                    </Route>,
                  ]}

                  <Route exact path="/pxvaluation">
                    <PXValuation authorisations={User?.Authorisations} />
                  </Route>

                  {/* <Route
                    exact
                    path="/salesOrders/:type/create"
                    component={VehicleOrderCreate}
                  /> */}

                  <Route exact path="/statements">
                    <Statements authorisations={User} />
                  </Route>
                  <Route exact path="/statements/details/:type/:month/:year">
                    <StatementsDetails />
                  </Route>
                  <Route exact path="/infoCenter" component={InfoCenter} />
                  <Route exact path="/infoCenter/create">
                    <InfoCenterCreate />
                  </Route>
                  <Route
                    exact
                    path="/infoCenter/:id"
                    component={InfoCenterCreate}
                  >
                    <InfoCenterCreate />
                  </Route>
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/privacy" component={Privacy} />

                  <Route exact path="/">
                    <Home authorisations={User?.Authorisations} user={User} />
                  </Route>
                </Switch>
              </Suspense>
            </div>
          </>
        )}
        {showCockpit && <Footer />}
      </Router>
    </Page>
  );
}
