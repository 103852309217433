import { FormattedMessage } from 'react-intl';

import * as S from './styled';

type Props = {
  messageId: string;
  onClick: Function;
};

function AsyncButton({ messageId, onClick }: Props) {
  const status: any = '';
  return (
    <S.LoginButton disabled={status === 'loading'} onClick={onClick as any}>
      {status === 'loading' ? (
        <FormattedMessage id="waiting" />
      ) : (
        <FormattedMessage id={messageId} />
      )}
    </S.LoginButton>
  );
}

export default AsyncButton;
