import {} from '@ui5/webcomponents-react';

import Title from 'components/UI5/Title';
import { useLanguages } from 'utils/hooks';

function Terms() {
  const { messages: lang } = useLanguages();
  return (
    <>
      <div style={{ padding: '1rem 2rem', width: '100%', height: '100%' }}>
        <iframe style={{ width: '100%', height: '100%' }} src="/tcs.html" />
      </div>
    </>
  );
}

export default Terms;
