import { useState, useEffect, useCallback } from 'react';

import { APIContext } from './Context';

let count1 = 0;
let count2 = 0;

export default function APIProvider({ children }): JSX.Element {
  const [incr, setIncr] = useState<number>(0);
  // const [count2, setCount2] = useState<number>(0);
  // const { user } = useLoggedIn();

  // const start = useCallback(() => {
  //   setCount1(count1 + 1);
  // }, [count1]);

  // const finish = useCallback(() => {
  //   setCount2(count2 + 1);
  // }, [count2]);

  const start = () => count1++;
  const finish = () => {
    count2++;
    setIncr(count2);
  };

  useEffect(() => {
    let tout;
    if (count1 !== count2) {
      tout = setTimeout(() => {
        // setCount2(count1);
        count1 = count2;
      }, 2000);
    }
    return () => tout && clearTimeout(tout);
    /* eslint-disable-next-line */
  }, [incr]);

  const reset = () => {
    return new Promise((resolve) => {
      resolve(true);
    });
  };

  const prefetch = useCallback(() => {}, []);

  // console.log('count', incr, count1, count2);
  return (
    <APIContext.Provider
      value={{ inProgress: count1 !== count2, start, finish, reset, prefetch }}
    >
      {children}
    </APIContext.Provider>
  );
}
