import { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import * as S from '../styled';
import AsyncButton from 'components/AsyncButton';
import LinkButton from 'components/LinkButton';
import TextInput from 'components/TextInput';
import { LanguageContext } from 'components/Language/Context';
import { ThemeContext } from 'components/Theme/Context';
import TextInput2 from 'components/TextInput2';
import styled from 'styled-components';

type State = {
  username: string;
  password: string;
};

const LoginHeader = styled.h1<any>`
  color: black;
  font-size: 24px;
  font-family: 'mmcbold';
  text-decoration: none;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  margin-bottom: 3em;
  margin-right: auto;
  text-align: center;
`;

const Login = styled.h1<any>`
  margin-left: 40%;
`;

function ResetPassword({
  accessToken,
  resetSuccess,
}: {
  accessToken: string;
  resetSuccess: Function;
}) {
  const [state, setState] = useState<State>({ username: '', password: '' });
  const history = useHistory();
  const { theme } = useContext(ThemeContext);
  const { messages } = useContext(LanguageContext);
  const { client }: any = useParams();

  const doReset = async () => {
    if (!state.password) {
      toast(
        <FormattedMessage
          id="loginFailed"
          values={{ message: 'Missing password' }}
        />,
        { type: 'error' }
      );
      return;
    }
    const error: any = { message: 'todo: reset' }; // await setPassword(accessToken, state.password);
    if (!error) {
      toast(<FormattedMessage id="resetSuccess" />, { type: 'success' });
      setTimeout(() => {
        // refreshLogin(true);
        resetSuccess();
        if (client) history.push(`/${client}/login`);
        else history.push('/');
      }, 1000);
    } else {
      toast(
        <FormattedMessage
          id="resetFailed"
          values={{ message: error.message }}
        />,
        { type: 'error' }
      );
    }
  };

  return (
    <S.Container theme={theme}>
      <Helmet>
        <title>{messages.login}</title>
      </Helmet>
      <div className="flex flex-row justify-center items-center">
        <S.Form theme={theme}>
          <div>
            <LoginHeader>{messages.resetPassword}</LoginHeader>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            {/* <TextInput value={state.username} label='username' onChange={(value: string) => setState({ ...state, username: value || '' })} /> */}
            <TextInput2
              value={state.password}
              password
              example={state.password ? '' : 'Password'}
              label={state.password ? 'Password' : ''}
              onChange={(value: string) =>
                setState({ ...state, password: value || '' })
              }
            />
            <AsyncButton onClick={doReset} messageId="submit2" />
          </form>
          <Login>
            <LinkButton
              onClick={() => {
                resetSuccess();
                if (client) history.push(`/${client}/login`);
                else history.push('/');
              }}
              messageId="login"
            />
          </Login>
        </S.Form>
      </div>
    </S.Container>
  );
}

export default ResetPassword;
