import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { LanguageContext } from 'components/Language/Context';

function Generic({ noHamburger }: { noHamburger?: boolean }) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { messages } = useContext(LanguageContext);

  const toggleMenu = () => setShowMenu(!showMenu);

  const userName = localStorage.getItem('xname');

  return (
    <div className="flex w-full items-center pr-3">
      <div className="flex">
        <span
          className={`text-center text-xxs font-normal font-sans mr-10 ${
            userName === undefined ? 'nav-active' : 'nav-inactive'
          }`}
        >
          <Link to="/">{messages.home}</Link>
        </span>
        <span className="text-center text-xxs font-normal font-sans">
          {messages.hello}, {userName}
        </span>
      </div>
      {!noHamburger && (
        <button className="p-3 m4" onClick={() => toggleMenu()}>
          <span className="fas fa-bars" />
        </button>
      )}
    </div>
  );
}

export default Generic;
