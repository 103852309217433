import { Title, TitleLevel } from '@ui5/webcomponents-react';

import { createUseStyles } from 'react-jss';

const styles = {
  default: {
    fontFamily: 'Oswald',
  },
};

function CustomTitle(props) {
  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  let titleLevel = 'H2';
  /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
  switch (props.type) {
    case 'page':
      titleLevel = TitleLevel.H2;
      break;
    case 'pageSubtitle':
    case 'tableHeader':
      titleLevel = TitleLevel.H3;
      break;
    case 'formSection':
      titleLevel = TitleLevel.H5;
      break;
  }

  return (
    <>
      <Title
        {...props}
        className={classes.default}
        children={props?.text?.toUpperCase()}
        level={titleLevel}
      />
    </>
  );
}

export default CustomTitle;
