import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { useLanguages } from 'utils/hooks';

function PageTitle() {
  const [feature, setFeature] = useState<string>();
  const { messages } = useLanguages();
  const location = useLocation();

  useEffect(() => {
    const value = location.pathname.split('/')[1];
    setFeature(value);
  }, [location]);

  return (
    <Helmet>
      <title>{`INEOS - ${messages[feature || 'home']}`}</title>
    </Helmet>
  );
}

export default PageTitle;
