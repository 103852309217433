import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { LanguageContext } from './Context';
import { Language } from 'types';

import en from './langs/en.json';
import es from './langs/es.json';

const definitions = {
  en,
  es,
};

const _locales = [
  { languageisocode: 'en', languageid: 1 },
  { languageisocode: 'es', languageid: 2 },
];

const defaultLocale = window?.localStorage.getItem('locale') || 'en';

let isFetching = false;

export default function LanguageProvider({ children }): JSX.Element {
  const [locale, setLocale] = useState<string>(defaultLocale);
  const [locales, setLocales] = useState<Language[]>();
  const [languageId, setLanguageId] = useState<any>();

  useEffect(() => {
    if (locales || isFetching) return;
    const f = async () => {
      isFetching = true;
      const data: any = _locales; // await supabase?.from('tb_languageid_new')?.select('*');
      setLocales(data);
      isFetching = false;
    };
    f();
  }, [locales]);

  useEffect(() => {
    const loc: Language | undefined = locales?.find(({ languageisocode }) =>
      languageisocode.includes(locale)
    );
    setLanguageId(loc?.languageid);
  }, [locale, locales]);

  const updateLocale = (l: string) => {
    const loc: Language | undefined = locales?.find(
      ({ languageid }) => languageid.toString() === l
    );
    if (!loc) return;
    const iso = loc.languageisocode.substring(0, 2);
    window?.localStorage.setItem('locale', iso || '');
    setLocale(iso);
    setLanguageId(loc.languageid);
  };

  return (
    <LanguageContext.Provider
      value={{
        setLocale: updateLocale,
        locale,
        messages: definitions[locale],
        locales,
        languageId,
      }}
    >
      <IntlProvider
        messages={definitions[locale]}
        locale={locale}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
