import {
  ResponsivePopover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  NotificationListItem,
  NotificationAction,
  List,
  Toast,
  MessageBox,
  MessageBoxTypes,
  Text,
  ListGrowingMode,
} from '@ui5/webcomponents-react';

import '@ui5/webcomponents-icons/dist/AllIcons.js';

import { MutableRefObject, useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { createPortal } from 'react-dom';

import { useApi } from 'utils/api';
import { useLanguages } from 'utils/hooks';

type Props = {
  popoverRef: MutableRefObject<any>;
  items: any;
  refresh: Function;
};

const styles = {
  notification: {
    borderBottom: '1px solid var(--ineos_secondaryUIGrey2)',
  },
  popover: {
    width: '32rem',
  },
  list: {
    padding: '1rem',
  },
  footnotes: {
    fontSize: 'small',
    color: 'var(--ineos_secondaryTextGrey)',
  },
};

const toSentenceCase = (s) => {
  return s
    ? s.substring(0, 1).toUpperCase() + s.substring(1, s.length).toLowerCase()
    : '';
};

function NotificationsListPopover({ popoverRef, items, refresh }: Props) {
  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  const { messages: lang } = useLanguages();
  const { callUpdate } = useApi();

  const [actionMessage, setActionMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const messageToastRef = useRef<any>();
  const notifications: any[] = [];

  const handleExecuteAction = async (id, actionId) => {
    popoverRef.current.close();
    const c = await callUpdate('notificationAction', {
      NotificationId: id,
      ActionId: actionId,
    });
    setActionMessage(c.MessageText);
    if (c.Success) {
      messageToastRef.current.show();
      document?.getElementById(id)?.setAttribute('style', 'display: none');
      refresh();
    } else {
      setShowError(true);
    }
  };

  const handleDismiss = (e) => {
    callUpdate('notificationDismiss', {
      NotificationId: e.currentTarget.id,
    });

    e.currentTarget.setAttribute('data-dismissed', '');
    e.currentTarget.setAttribute('style', 'display: none');

    const children: any[] = [];
    document
      ?.getElementById('notifications-list')
      ?.childNodes?.forEach((c) => children.push(c));
    if (children.every((n) => n.hasAttribute('data-dismissed'))) {
      popoverRef.current.close();
      refresh();
    }
  };

  // TODO find a better way to calculate elapsed time
  const getElapsedTime = (t) => {
    const currentTime = new Date().getTime();
    const notifTime = new Date(t).getTime();
    const diff = currentTime - notifTime;
    const s = Math.floor(diff / 1000);
    const m = Math.floor(diff / 1000 / 60);
    const h = Math.floor(diff / 1000 / 60 / 60);
    const d = Math.floor(diff / 1000 / 60 / 60 / 24);
    if (s < 10) {
      return lang['now'];
    } else if (s >= 10 && s < 60) {
      return s + ' ' + lang['second' + (m === 1 ? '' : 's') + 'Elapsed'];
    } else if (m >= 1 && m < 59) {
      return m + ' ' + lang['minute' + (m === 1 ? '' : 's') + 'Elapsed'];
    } else if (h >= 1 && h < 24) {
      return h + ' ' + lang['hour' + (h === 1 ? '' : 's') + 'Elapsed'];
    } else {
      return d + ' ' + lang['day' + (d === 1 ? '' : 's') + 'Elapsed'];
    }
  };

  return (
    <>
      {items.forEach((i) => {
        const actions: any[] = [];
        i.Actions.forEach((a) => {
          actions.push(
            <NotificationAction
              key={a.ActionId}
              text={a.ActionText}
              onClick={(e) => handleExecuteAction(i.Id, a.ActionId)}
            />
          );
        });
        notifications.push(
          <NotificationListItem
            className={classes.notification}
            key={i.Id}
            id={i.Id}
            showClose
            titleText={i.SensitiveText}
            priority={toSentenceCase(i.Priority)}
            actions={actions}
            footnotes={
              <Text className={classes.footnotes}>
                {getElapsedTime(i.CreatedAt)}
              </Text>
            }
            onClose={(e) => handleDismiss(e)}
          >
            {i.SubTitle}
          </NotificationListItem>
        );
      })}
      <ResponsivePopover
        className={classes.popover}
        ref={popoverRef}
        hideArrow={false}
        horizontalAlign={PopoverHorizontalAlign.Right}
        placementType={PopoverPlacementType.Bottom}
      >
        <List
          id="notifications-list"
          className={classes.list}
          growing={ListGrowingMode.Scroll}
          header={
            items.length === 0 ? (
              <div style={{ textAlign: 'center' }}>
                <Text>{lang['noNotifications']}</Text>
              </div>
            ) : null
          }
        >
          {notifications}
        </List>
      </ResponsivePopover>
      {createPortal(
        <Toast ref={messageToastRef}>{actionMessage}</Toast>,
        document.body
      )}
      {createPortal(
        <MessageBox
          open={showError}
          type={MessageBoxTypes.Error}
          onClose={() => setShowError(false)}
        >
          {actionMessage}
        </MessageBox>,
        document.body
      )}
    </>
  );
}

export default NotificationsListPopover;
