import {} from '@ui5/webcomponents-react';

import Title from 'components/UI5/Title';
import { useLanguages } from 'utils/hooks';

function Privacy() {
  const { messages: lang } = useLanguages();
  return (
    <>
      <div style={{ padding: '1rem 2rem', width: '100%' }}>
        <Title type='page' text={lang['privacy']} />
      </div>
    </>
  );
}

export default Privacy;
