import {
  ShellBar,
  Button,
  Avatar,
  AvatarSize,
  AvatarColorScheme,
  ShellBarItem,
} from '@ui5/webcomponents-react';
import { Device } from '@ui5/webcomponents-react-base';

import { useEffect, useRef, useState } from 'react';

import { useApi } from 'utils/api';
import { useInterval, useLanguages } from 'utils/hooks';
import GrowlNotification from 'assets/growl/growl-notification';
import NotificationsListPopover from 'components/UI5/NotificationsListPopover';
import ProfilePopover from 'components/UI5/ProfilePopover';
import SideNavigation from 'components/UI5/SideNavigation';
import { useHistory } from 'react-router-dom';

let notificationsCurrent: any[] = [];
let notificationsNew: any[] = [];

function Bar({ userInitials, showLogin, User, xuser }) {
  const [notificationsBadge, setNotificationsBadge] = useState('');
  const { call, callUpdate } = useApi();
  const { messages: lang } = useLanguages();
  const history = useHistory();
  const notificationPopoverRef = useRef<any>(null);
  const profilePopoverRef = useRef<any>(null);
  const countryKey = localStorage.getItem('countryKey');
  const showPXValuation = countryKey === 'GB' ? true : false;
  const getNotificationsBadge = async () => {
    const badge = await call('notificationsBadge');
    setNotificationsBadge(badge !== 0 ? badge : '');
  };

  const resetNotificationsBadge = async () => {
    const r = await callUpdate('notificationsBadgeReset', {});
    if (r.status === 201 || r.status === 204) setNotificationsBadge('');
  };

  const getNotificationsList = async () => {
    const notifs = await call('notificationsList');
    // TODO: check why there's an extra notification without an Actor Id
    const filterdNotifs = notifs.filter((n) => !!n.Actor.Id);
    notificationsNew = filterdNotifs;
  };

  const getNotificationsCurrent = async () => {
    const notifs = await call('notificationsList');
    // TODO: check why there's an extra notification without an Actor Id
    const filterdNotifs = notifs.filter((n) => !!n.Actor.Id);
    notificationsCurrent = filterdNotifs;
  };

  const getNotificationsNew = async () => {
    const notifs = await call('notificationsList');
    // TODO: check why there's an extra notification without an Actor Id
    const filterdNotifs = notifs.filter((n) => !!n.Actor.Id);
    notificationsNew = filterdNotifs;

    const toastItems: any[] = [];
    notificationsNew.forEach((n) => {
      const idx = notificationsCurrent.findIndex((i) => n.Id === i.Id);
      if (idx === -1) toastItems.push(n);
    });
    if (toastItems.length > 0) {
      notificationsCurrent = notificationsNew;
      // Previous approach using the NotificationsToastContainer
      // const t = setTimeout(() => {
      //   setShowNotificationToasts(false);
      //   clearTimeout(t);
      // }, 5000);

      // setNotificationToasts(toastItems);
      // setShowNotificationToasts(true);

      // TODO: find a better way to get the elapsed time
      const getElapsedTime = (t) => {
        const currentTime = new Date().getTime();
        const notifTime = new Date(t).getTime();
        const diff = currentTime - notifTime;
        const s = Math.floor(diff / 1000);
        const m = Math.floor(diff / 1000 / 60);
        const h = Math.floor(diff / 1000 / 60 / 60);
        const d = Math.floor(diff / 1000 / 60 / 60 / 24);
        if (s < 10) {
          return lang['now'];
        } else if (s >= 10 && s < 60) {
          return s + ' ' + lang['second' + (m === 1 ? '' : 's') + 'Elapsed'];
        } else if (m >= 1 && m < 59) {
          return m + ' ' + lang['minute' + (m === 1 ? '' : 's') + 'Elapsed'];
        } else if (h >= 1 && h < 24) {
          return h + ' ' + lang['hour' + (h === 1 ? '' : 's') + 'Elapsed'];
        } else {
          return d + ' ' + lang['day' + (d === 1 ? '' : 's') + 'Elapsed'];
        }
      };

      const notifType = {
        LOW: 'success',
        MEDIUM: 'warning',
        HIGH: 'error',
      };

      const desc =
        "<div class='custom-notif-subtitle'>@1@</div><div class='custom-notif-footer'>@2@</div>";
      toastItems.forEach((t) => {
        GrowlNotification.notify({
          title: t.Text,
          description: desc
            .replace('@1@', t.SubTitle)
            .replace('@2@', getElapsedTime(t.CreatedAt)),
          type: notifType[t.Priority],
          position: 'top-right',
          closeWith: 'button',
          closeTimeout: 5000,
        });
      });
    }
  };

  Device.attachResizeHandler((e) => {
    setShowSideNav(e.width >= 1280);
    document
      .getElementById('vss-shell-bar')
      ?.shadowRoot?.children[0]?.setAttribute(
        'style',
        'padding: 0.25rem 1rem 0.25rem 0.5rem !important;'
      );
  });

  const checkNotifications = () => {
    getNotificationsBadge();
    getNotificationsNew();
  };

  useEffect(() => {
    // getNotificationsCurrent();
    // checkNotifications();
    /* eslint-disable-next-line */
  }, []);

  // useInterval(checkNotifications, 30000, showLogin);
  const [showSideNav, setShowSideNav] = useState(
    Device.isDesktop() && window.outerWidth >= 1280
  );

  const handleOpenNotificationPopover = (e) => {
    if (notificationPopoverRef.current.isOpen()) {
      notificationPopoverRef.current.close();
    } else {
      notificationPopoverRef.current.showAt(e.detail.targetRef);
    }

    if (!!notificationsBadge) resetNotificationsBadge();
  };

  const handleOpenProfilePopover = (e) => {
    if (profilePopoverRef.current.isOpen()) {
      profilePopoverRef.current.close();
    } else {
      profilePopoverRef.current.showAt(e.detail.targetRef);
    }
  };

  const toggleMenu = () => {
    setShowSideNav(!showSideNav);
  };

  useEffect(() => {
    document
      .getElementById('vss-shell-bar')
      ?.shadowRoot?.querySelector("div[role='banner']")
      ?.setAttribute(
        'style',
        'padding: 0.25rem 1rem 0.25rem 0.5rem !important;'
      );
  }, []);

  return (
    <>
      <ShellBar
        id="vss-shell-bar"
        startButton={<Button icon="menu2" onClick={toggleMenu} />}
        profile={
          <Avatar
            id="profile-avatar"
            initials={userInitials}
            icon={!userInitials ? 'employee' : ''}
            size={AvatarSize.XS}
            colorScheme={AvatarColorScheme.Accent10}
          />
        }
        onProfileClick={handleOpenProfilePopover}
        // showNotifications
        // notificationsCount={notificationsBadge}
        // onNotificationsClick={handleOpenNotificationPopover}
      >
        {/* <ShellBarItem
          icon="navigation-left-arrow"
          onClick={() => history.goBack()}
          text="Back"
          color="#FFF"
        >
          <button>Back</button>
        </ShellBarItem> */}
      </ShellBar>
      <NotificationsListPopover
        popoverRef={notificationPopoverRef}
        items={notificationsNew}
        refresh={getNotificationsList}
      />
      <SideNavigation
        open={showSideNav}
        setOpen={setShowSideNav}
        authorisations={User?.Authorisations}
        display={showPXValuation}
        user={User}
      />
      <ProfilePopover popoverRef={profilePopoverRef} user={xuser} />
    </>
  );
}

export default Bar;
