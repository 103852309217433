import { ValueState, Icon } from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';
import { v4 } from 'uuid';

import { useControlSpan, useRandomId } from './utils/hooks';
import { useLanguages } from 'utils/hooks';

import '@ui5/webcomponents-icons/dist/AllIcons.js';
import { useMemo } from 'react';

const Input = styled.input<any>`
  font-size: 1rem;
  padding: 0.5rem;
  color: black;
  ${(props) =>
    props.type === 'checkbox'
      ? 'flex-grow: 0 !important; margin-left: 1rem;'
      : ''}
  width: 90%;
  :disabled {
    background-color: white;
    color: darkgray;
  }
  @media (max-width: 900px) {
    width: 90% !important;
  }
  ${(props) => (props?.$hasError ? 'border-color: red !important;' : '')}
`;

const styles = {
  default: {
    fontFamily: 'Open Sans',
    borderRadius: '5px',
    border: '1px solid var(--ineos_secondaryUIGrey2)',
    '&:hover': {
      borderColor: 'var(--ineos_secondaryUIGrey1)',
    },
    '&:active': {
      borderColor: 'var(--ineos_primaryBlue)',
    },
    '&:focus-within': {
      borderColor: 'var(--ineos_primaryBlue)',
    },
    '&[value-state="Error"]': {
      borderColor: 'var(--ineos_stateError)',
    },
  },
};

function CustomInput(props) {
  const { messages: lang } = useLanguages();
  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  const rId = useMemo(() => v4(), []);
  const actualId = props.id || rId;
  useControlSpan(actualId, props.controlSpan);

  return (
    <>
      <Input
        {...props}
        id={actualId}
        className={classes.default || props.className}
        valueStateMessage={
          <div>{props.valueStateMessage || lang['defaultVSM']}</div>
        }
        onChange={props.onInput || props.onChange}
        icon={
          <div style={{ height: '16px' }}>
            {props.valueState === ValueState.Error ? (
              <Icon name="alert" style={{ color: 'var(--ineos_stateError)' }} />
            ) : props.icon ? (
              <Icon name={props.icon} />
            ) : null}
          </div>
        }
        children={props.children}
      />
    </>
  );
}

export default CustomInput;
