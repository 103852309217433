import {
  BusyIndicator,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Avatar,
  AvatarShape,
  AvatarSize,
  Label,
  ValueState,
  Link,
} from '@ui5/webcomponents-react';

import Title from './Title';
import Input from './Input';
import Button from './Button';
import ContentDialog from './ContentDialog';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './SSO/authConfig';
import GetBearerToken from 'components/UI5/SSO/ssoHelper';

import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';

import { useCurrentSize } from './utils/hooks';
import { useLanguages } from 'utils/hooks';

import logo from '../../assets/ineos_logo.png';
import login_photo from '../../assets/ineos_login.png';

type Props = {
  setShowLogin: Function;
  setUser: Function;
};

const styles = {
  formContainer: {
    padding: '1rem 4rem',
    border: '1px solid var(--ineos_secondaryUIGrey2)',
    borderRight: 'none',
    borderRadius: '10px 0px 0px 10px',
    height: '346px',
    justifyContent: 'center',
  },
  formContainerS: {
    padding: '1.7rem 4rem',
    border: '1px solid var(--ineos_secondaryUIGrey2)',
    borderRadius: '10px',
    height: '346px',
  },
  imageContainer: {
    border: '1px solid var(--ineos_secondaryUIGrey2)',
    borderLeft: 'none',
    borderRadius: '0px 10px 10px 0px',
  },
  imageContainerS: {
    display: 'none',
  },
};

function CustomLogin({ setShowLogin, setUser }: Props) {
  const { messages: lang } = useLanguages();
  const contentDialogRef = useRef<any>(null);

  const [dialogContent, setDialogContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setErrorType] = useState('');
  const [inputValue, setInputValue] = useState({
    username: '',
    password: '',
  });
  const [valueState, setValueState] = useState({
    username: ValueState.None,
    password: ValueState.None,
  });
  const [valueStateMessage, setValueStateMessage] = useState({
    username: '',
    password: '',
  });
  const errorMsg = {
    required: lang['requiredFieldMsg'],
    credentials: lang['loginErrorMsg'],
  };

  const handleInput = ({ target: { id, value } }) => {
    setInputValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    handleInputVS(id, value.trim(), 'required');
  };

  const handleInputVS = (id, value, type) => {
    const vs = value
      ? type === 'credentials'
        ? ValueState.Error
        : ValueState.None
      : ValueState.Error;
    setValueState((prevState) => ({
      ...prevState,
      [id]: vs,
    }));

    setValueStateMessage((prevState) => ({
      ...prevState,
      [id]: errorMsg[type],
    }));

    setErrorType(vs === ValueState.Error ? type : '');
  };

  const setLoginError = () => {
    handleInputVS(
      'username',
      inputValue.username,
      !inputValue.username.trim() ? 'required' : 'credentials'
    );
    handleInputVS(
      'password',
      inputValue.password,
      !inputValue.password.trim() ? 'required' : 'credentials'
    );
  };

  const handleLogin = async () => {
    if (!inputValue.username && !inputValue.password) {
      setLoginError();
      return;
    }

    try {
      setLoading(true);
      const oHeaders = new Headers();
      // oHeaders.append('Access-Control-Allow-Origin', '*');
      oHeaders.append(
        'Authorization',
        'Basic ' + btoa(inputValue.username + ':' + inputValue.password)
      );
      const response = await fetch(
        "https://sap-dev.ineosautomotive.com/sap/opu/odata/ENCPP/DP_CORE_SRV/Users('" +
          inputValue.username +
          "')?$expand=UserRoles&$format=json",
        {
          method: 'GET',
          headers: oHeaders,
        }
      );
      // const response = await fetch(
      //   'https://musing-swartz-008738.netlify.app/.netlify/functions/assetRedirect',
      //   {
      //     method: 'POST',
      //     body: JSON.stringify({ username,password}),
      //   }
      // );
      // console.log('Response from netlify function');
      // console.log(response);
      console.log('This is from login =================');
      console.log(response);
      if (response.status === 401) {
        setLoginError();
        setLoading(false);
        return;
      }
      const data = await response.json();
      setUser(data?.d);

      // for DEMO purposes only
      localStorage.setItem('fullName', data?.d?.FullName);
      document.cookie = 'FULLNAME=' + data?.d?.FullName;

      setLoading(false);
      setShowLogin(false);
    } catch (e: any) {
      setLoading(false);
      setLoginError();
    }
  };

  const loginWithSSO = async () => {
    try {
      window.location.replace(
        `https://login.microsoftonline.com/4029eaa5-ce94-4d28-81b9-5cac5e5cf88f/oauth2/v2.0/authorize?response_type=code&client_id=97a5ca1d-d29f-4c79-b930-ed1aac04e529&scope=api%3A%2F%2F97a5ca1d-d29f-4c79-b930-ed1aac04e529%2Fuser_impersonation&redirect_uri=${window.location.origin}/`
      );
    } catch (e: any) {
      setLoading(false);
      setLoginError();
    }
  };

  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  const [currentSize, setCurrentSize] = useState(useCurrentSize());
  const [showImage, setShowImage] = useState(
    currentSize === 'L' || currentSize === 'XL'
  );
  // TO DO
  useEffect(() => {
    const resized = () => {
      setCurrentSize(
        window.outerWidth >= 1440
          ? 'XL'
          : window.outerWidth >= 1024 && window.outerWidth < 1440
          ? 'L'
          : window.outerWidth >= 600 && window.outerWidth < 1024
          ? 'M'
          : 'S'
      );
      setShowImage(currentSize === 'L' || currentSize === 'XL');
    };
    window.addEventListener('resize', resized);
    resized();
    return () => {
      window.removeEventListener('resize', resized);
    };
  }, [currentSize]);

  useEffect(() => {
    // If param contain code
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const session_state = query.get('session_state');

    console.log('This is code and session_state');
    console.log(code);
    console.log(session_state);
    if (code && session_state) {
      console.log('Calling get bearer token ');
      // getBearerToken(code);
    }
    /* eslint-disable-next-line */
  }, []);

  const getBearerToken = async (code) => {
    console.log('In the bearer token function');
    try {
      setLoading(true);
      let headersList = {
        // Accept: '*/*',
        // 'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
        // Origin: window.location.origin + '/',
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      let bodyContent = `code=${code}&client_id=97a5ca1d-d29f-4c79-b930-ed1aac04e529&client_secret=D3m7Q~m1dx2nQt6cTReGTVqAcxN_UKYY3RQIk&grant_type=authorization_code&redirect_uri=${window.location.origin}/`;

      const response = await fetch(
        'https://login.microsoftonline.com/4029eaa5-ce94-4d28-81b9-5cac5e5cf88f/oauth2/v2.0/token',
        {
          method: 'POST',
          body: bodyContent,
          headers: headersList,
        }
      );
      console.log('Below is the response for bearer token ');
      console.log(response);
      if (response.status === 401) {
        setLoginError();
        setLoading(false);
        return;
      }
      const data = await response.json();
      console.log('Below is data in json from bearer token');
      console.log(data);
      console.log(response.status);
      console.log(data?.access_token);
      // data?.access_token ? setUser({ access_token: data?.access_token }) : null;
      localStorage.setItem('SSOBearerToken', data?.access_token);
      document.cookie = 'SSOBearerToken=' + data?.access_token;

      fetchSampleResponse(data?.access_token);
      setSSOUser(data?.access_token);
      // sample request
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      setLoginError();
    }
  };

  const fetchSampleResponse = async (token) => {
    let headersList = {
      // Accept: '*/*',
      // 'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
      'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY as any,
      Authorization: `Bearer ${token}`,
    };

    const res = await fetch(
      process.env.REACT_APP_AGENT_PORTAL_API_URL +
        'v1.0/sap/opu/odata/ENCPP/C_AGENT_ORDERS_CDS/xENCPPxc_agent_orders?$format=json',
      {
        method: 'GET',
        headers: headersList,
      }
    );

    const data = await res.json();

    console.log('Sample API call data');
    console.log(data);
  };

  const setSSOUser = async (token) => {
    let headersList = {
      //   Accept: '*/*',
      //   'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
      'subscription-key': process.env.REACT_APP_AGENT_PORTAL_API_SUB_KEY as any,
      Authorization: `Bearer ${token}`,
    };

    // The somevalue is needed to get data
    const res = await fetch(
      (process.env.REACT_APP_AGENT_PORTAL_API_URL +
        "v1.0/sap/opu/odata/ENCPP/DP_CORE_SRV/Users('Somevalue')?$format=json") as any,
      {
        method: 'GET',
        headers: headersList,
      }
    );

    const data = await res.json();
    console.log('Below is the user value with bearer token');
    console.log(data?.d);
    setUser(data?.d);

    // for DEMO purposes only
    localStorage.setItem('fullName', data?.d?.FullName);
    document.cookie = 'FULLNAME=' + data?.d?.FullName + '; SameSite = Lax';
    setLoading(false);
    setShowLogin(false);
  };

  const handleContentDialog = (e) => {
    setDialogContent(e.currentTarget.getAttribute('data-type'));
    contentDialogRef.current.show();
  };

  const { instance, accounts } = useMsal();

  const handleLoginSSOMSAL = async () => {
    setLoading(true);
    instance
      .loginPopup(loginRequest as any)
      .then(async (context) => {
        console.log('About to set user');
        setSSOUser(context.accessToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <BusyIndicator
        active={loading}
        delay={0}
        style={{
          color: 'var(--ineos_primaryRed)',
          width: '100vw',
          height: '100vh',
        }}
      >
        <form
          onSubmit={(e) => {
            console.log('HERE');
            e.preventDefault();
            handleLogin();
          }}
        >
          <FlexBox
            style={{ width: '100vw', height: '80vh' }}
            alignItems={FlexBoxAlignItems.Center}
            justifyContent={FlexBoxJustifyContent.Center}
          >
            <FlexBox
              direction={FlexBoxDirection.Column}
              className={
                showImage ? classes.formContainer : classes.formContainerS
              }
            >
              <Avatar shape={AvatarShape.Square} size={AvatarSize.S}>
                <img style={{ width: '5rem' }} src={logo} />
              </Avatar>
              <Title type="formSection" text={lang['loginTitle']} />
              {/* <Label style={{ paddingTop: '1rem' }}>{lang['username']}</Label>
              <Input
                id="username"
                valueState={valueState.username}
                valueStateMessage={valueStateMessage.username}
                onInput={handleInput}
              />
              <Label style={{ paddingTop: '0.5rem' }}>{lang['password']}</Label>
              <Input
                id="password"
                valueState={valueState.password}
                valueStateMessage={valueStateMessage.password}
                type="Password"
                onInput={handleInput}
              />
              <Button
                isSubmit
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                type="Primary"
                text={lang['login']}
                // onClick={handleLogin}
              />
              <Button
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                type="Primary"
                text={lang['loginWithSSO']}
                onClick={loginWithSSO}
              /> */}
              <Button
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                type="Primary"
                text={lang['login']}
                onClick={handleLoginSSOMSAL}
              />
              <FlexBox
                alignItems={FlexBoxAlignItems.Center}
                justifyContent={FlexBoxJustifyContent.SpaceBetween}
              >
                {/* <Link
                  style={{ fontSize: 'x-small' }}
                  data-type="eula"
                  onClick={handleContentDialog}
                >
                  {lang['eula']}
                </Link> */}
                <Link
                  style={{ fontSize: 'x-small' }}
                  data-type="terms"
                  onClick={handleContentDialog}
                >
                  {lang['terms']}
                </Link>
                {/* <Link
                  style={{ fontSize: 'x-small' }}
                  data-type="privacy"
                  onClick={handleContentDialog}
                >
                  {lang['privacy']}
                </Link> */}
              </FlexBox>
            </FlexBox>
            <FlexBox
              direction={FlexBoxDirection.Column}
              className={
                showImage ? classes.imageContainer : classes.imageContainerS
              }
            >
              <img
                src={login_photo}
                style={{ borderRadius: '0px 10px 10px 0px' }}
              />
            </FlexBox>
          </FlexBox>
        </form>
      </BusyIndicator>
      {createPortal(
        <ContentDialog
          dialogRef={contentDialogRef}
          url=""
          type={dialogContent}
        />,
        document.body
      )}
    </>
  );
}

export default CustomLogin;
