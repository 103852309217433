import { FormattedMessage } from 'react-intl';

import * as S from '../styled';

const FSLoader = () => (
  <>
    <S.FullScreenLoaderBG />
    <S.FullScreenLoader>
      <FormattedMessage id="loading" />
      ...
    </S.FullScreenLoader>
  </>
);

export default FSLoader;
