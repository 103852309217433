import {
  Dialog,
  Bar,
  Form,
  FormItem,
  Label,
  Text,
} from '@ui5/webcomponents-react';

import Title from './Title';
import Input from './Input';
import Select from './Select';
import Button from './Button';

import { MutableRefObject, useEffect, useState } from 'react';

import { useApi } from 'utils/api';
import { useLanguages } from 'utils/hooks';
import { useCurrentSize } from './utils/hooks';
import { VSSUser } from 'types';

type Props = {
  dialogRef: MutableRefObject<any>;
  user?: VSSUser;
};

function MyAccountDialog({ dialogRef, user }: Props) {
  const { callUpdate, call } = useApi();
  const { messages: lang } = useLanguages();
  const [currentSize, setCurrentSize] = useState(useCurrentSize());

  // TO DO
  useEffect(() => {
    const resized = () => {
      setCurrentSize(
        window.outerWidth >= 1440
          ? 'XL'
          : window.outerWidth >= 1024 && window.outerWidth < 1440
          ? 'L'
          : window.outerWidth >= 600 && window.outerWidth < 1024
          ? 'M'
          : 'S'
      );
    };
    window.addEventListener('resize', resized);
    resized();
    console.log(user);
    console.log('Below is the user from my account');
    return () => {
      window.removeEventListener('resize', resized);
    };
  }, [user]);

  return (
    <>
      <Dialog
        stretch={currentSize === 'S'}
        style={
          currentSize === 'L' || currentSize === 'XL'
            ? { width: '45%' }
            : currentSize === 'M'
            ? { width: '60%' }
            : { width: '100vw' }
        }
        headerText={lang['myAccount']}
        ref={dialogRef}
        footer={
          <Bar
            design="Footer"
            endContent={
              <>
                <Button
                  type="Text"
                  text={lang['close']}
                  slot="endContent"
                  onClick={(e) => {
                    dialogRef.current.close();
                  }}
                />
                {/* <Button
                  type='Primary'
                  text={lang['save']}
                  slot='endContent'
                  onClick={() => {}}
                /> */}
              </>
            }
          />
        }
      >
        <div style={{ padding: '1rem' }}>
          <Title type="formSection" text={lang['profile']} />
          <Form
            style={{ paddingBottom: '1rem' }}
            columnsXL={1}
            columnsL={1}
            labelSpanXL={4}
            labelSpanL={3}
            labelSpanM={3}
          >
            <FormItem label={<Label>{lang['name']}</Label>}>
              <Text>{user?.Name}</Text>
            </FormItem>
            {/* <FormItem label={<Label>{lang['dealership']}</Label>}>
              <Text>{''}</Text>
            </FormItem>
            <FormItem label={<Label>{lang['role']}</Label>}>
              <Text>{''}</Text>
            </FormItem> */}
            <FormItem label={<Label>{lang['country']}</Label>}>
              <Text>{user?.CountryName}</Text>
            </FormItem>
          </Form>
          <Title type="formSection" text={lang['contactDetails']} />
          <Form
            style={{ paddingBottom: '1rem' }}
            columnsXL={1}
            columnsL={1}
            labelSpanXL={4}
            labelSpanL={3}
            labelSpanM={3}
          >
            <FormItem label={<Label>{lang['phone']}</Label>}>
              <Input disabled value={user?.Phone} />
            </FormItem>
            <FormItem label={<Label>{lang['mobile']}</Label>}>
              <Input disabled value={user?.MobilePhone} />
            </FormItem>
            <FormItem label={<Label>{lang['email']}</Label>}>
              <Input disabled value={user?.Email} />
            </FormItem>
          </Form>
          <Title type="formSection" text={lang['preferences']} />
          <Form
            style={{ paddingBottom: '1rem' }}
            columnsXL={1}
            columnsL={1}
            labelSpanXL={4}
            labelSpanL={3}
            labelSpanM={3}
          >
            <FormItem label={<Label>{lang['language']}</Label>}>
              <Input disabled value={user?.Language} />
            </FormItem>
          </Form>
        </div>
      </Dialog>
    </>
  );
}

export default MyAccountDialog;
