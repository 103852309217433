import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Generic from './components/Generic';

function Footer() {
  return <Generic />;
}

export default Footer;
