import styled from 'styled-components';

export const Container = styled.div<{ $width?: string }>`
  position: relative;
  height: 100vh;
  width: ${(props) => props?.$width || '100vw'};
  background: ${({ theme }) => theme.BG};
  color: ${({ theme }) => theme.Text};
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 40%;
  height: 4em;
  margin-top: -2em;
`;

export const FullScreenLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const FullScreenLoaderBG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: darkgrey;
  opacity: 0.3;
  z-index: 999;
`;
