// import { Button } from '@ui5/webcomponents-react';

import { createUseStyles } from 'react-jss';
import styled, { CSSProperties } from 'styled-components';
import { ReactNode } from 'react';

const Button = styled.button`
  padding: 0.5rem;
`;

type Props = {
  id?: string;
  type?: string;
  slot?: string;
  style?: CSSProperties;
  className?: CSSProperties;
  text?: string;
  icon?: string;
  iconEnd?: boolean;
  disabled?: boolean;
  onClick?: any;
  isSubmit?: boolean;
  key?: string;
  children?: ReactNode;
};

const styles = {
  defaultPrimary: {
    fontFamily: 'Oswald',
    color: '#fff',
    border: '1px solid var(--ineos_primaryRed)',
    backgroundColor: 'var(--ineos_primaryRed)',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'var(--ineos_primaryRed)',
    },
    '&:focus': {
      backgroundColor: '#fff',
      color: 'var(--ineos_primaryRed)',
    },
    '&:active': {
      backgroundColor: '#fff',
      color: 'var(--ineos_primaryRed)',
    },
  },
  disabledPrimary: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_secondaryUIGrey1)',
    border: '1px solid var(--ineos_secondaryUIGrey1)',
    backgroundColor: 'var(--ineos_secondaryUIGrey2)',
  },
  defaultSecondary: {
    fontFamily: 'Oswald',
    color: '#fff',
    border: '1px solid var(--ineos_primaryBlue)',
    backgroundColor: 'var(--ineos_primaryBlue)',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'var(--ineos_primaryBlue)',
    },
    '&:active': {
      backgroundColor: '#fff',
      color: 'var(--ineos_primaryBlue)',
    },
  },
  disabledSecondary: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_secondaryUIGrey1)',
    border: '1px solid var(--ineos_secondaryUIGrey1)',
    backgroundColor: 'var(--ineos_secondaryUIGrey2)',
  },
  defaultTertiary: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_primaryRed)',
    border: '1px solid var(--ineos_primaryRed)',
    backgroundColor: '#fff',
    '&:hover': {
      color: 'var(--ineos_primaryRed)',
    },
    '&:active': {
      color: 'var(--ineos_primaryRed)',
    },
  },
  disabledTertiary: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_secondaryUIGrey1)',
    border: '1px solid var(--ineos_secondaryUIGrey1)',
    backgroundColor: 'var(--ineos_secondaryUIGrey2)',
  },
  defaultQuaternary: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_primaryBlue)',
    border: '1px solid var(--ineos_primaryBlue)',
    backgroundColor: '#fff',
    '&:hover': {
      color: 'var(--ineos_primaryBlue)',
    },
    '&:active': {
      color: 'var(--ineos_primaryBlue)',
    },
  },
  disabledQuaternary: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_secondaryUIGrey1)',
    border: '1px solid var(--ineos_secondaryUIGrey1)',
    backgroundColor: 'var(--ineos_secondaryUIGrey2)',
  },
  defaultText: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_primaryRed)',
    border: '0px',
    backgroundColor: '#fff',
  },
  disabledText: {
    fontFamily: 'Oswald',
    color: 'var(--ineos_secondaryUIGrey1)',
    backgroundColor: '#fff',
  },
};

function CustomButton(props: Props) {
  const useStyles = createUseStyles(styles);
  const classes = useStyles();
  const { isSubmit, type, text, disabled, iconEnd, slot, key, children } =
    props;
  const sButtonType =
    ['Primary', 'Secondary', 'Tertiary', 'Quaternary', 'Text'].indexOf(
      type || ''
    ) !== -1
      ? type
      : 'Primary';
  return (
    <Button
      {...props}
      type={isSubmit ? 'submit' : 'button'}
      // iconEnd={typeof iconEnd === 'boolean' ? iconEnd : true}
      disabled={disabled}
      className={
        !disabled
          ? classes['default' + sButtonType]
          : classes['disabled' + sButtonType]
      }
    >
      {text?.toUpperCase()}
      {children}
    </Button>
  );
}

export default CustomButton;
