import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider as UI5Theme } from '@ui5/webcomponents-react';

import 'react-toastify/dist/ReactToastify.css';
import '@ui5/webcomponents/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets.js';

import './index.css';
import './css_variables.css';
import 'assets/growl/dark-theme.css';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import * as serviceWorker from './serviceWorker';
import App from 'features/page';
import LanguageProvider from 'components/Language/Provider';
import AppTheme from 'components/Theme/Provider';
import APIProvider from 'components/API/Provider';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './components/UI5/SSO/authConfig';

const msalInstance = new PublicClientApplication(msalConfig as any);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RecoilRoot>
        <UI5Theme>
          <LanguageProvider>
            <ToastContainer />
            <HelmetProvider>
              <AppTheme>
                <APIProvider>
                  <App />
                </APIProvider>
              </AppTheme>
            </HelmetProvider>
          </LanguageProvider>
        </UI5Theme>
      </RecoilRoot>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
