import React from 'react';

type T = {
  start: Function;
  finish: Function;
  inProgress: boolean;
  reset: Function;
  prefetch: Function;
};

export const APIContext = React.createContext<T>({
  inProgress: false,
  start: () => {},
  finish: () => {},
  reset: () => {},
  prefetch: () => {},
});
