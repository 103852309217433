import styled from 'styled-components';
import Color from 'color';

export const LinkColor = Color('#5a646e');

export const ButtonColor = Color('black');

export const ButtonHover = Color('black');

export const DarkBGColor = Color('black');

export const Padded = styled.span`
  display: inline-block;
  padding: 1em;
`;
