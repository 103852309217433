import styled from 'styled-components';

import { ButtonColor, ButtonHover } from 'styled';

export const LoginButton = styled.button`
  padding: 8px;
  border: none;
  background: ${(props) => (props.disabled ? 'grey' : ButtonColor.toString())};
  color: white;
  width: 100%;
  margin-top: 2em;
  font-size: 14px;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
  font-family: 'mmcbold';
  //border-radius: 0.5em;
  :active {
    background: ${ButtonHover.lighten(0.3).string()};
  }

  :hover {
    background: ${ButtonHover.lighten(0.3).string()};
  }
`;
