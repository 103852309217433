import { useEffect } from 'react';
import { Device } from '@ui5/webcomponents-react-base';

// Breakpoints are based on the styling guide of INEOS
export function useCurrentSize() {
  return window.innerWidth > 1920
    ? 'XL'
    : window.innerWidth > 1280 && window.innerWidth <= 1920
      ? 'L'
      : window.innerWidth > 768 && window.innerWidth <= 1280
        ? 'M'
        : 'S';
}

export function useRandomId() {
  return (
    String.fromCharCode(Math.floor(Math.random() * 26) + 97) +
    Math.random().toString(16).slice(2) +
    Date.now().toString(16).slice(4)
  );
}

export function useControlSpan(id, controlSpan) {
  useEffect(() => {
    if (!controlSpan) return;
    const adjustSpan = () => {
      const currentSize =
        window.innerWidth > 1920
          ? 'XL'
          : window.innerWidth > 1280 && window.innerWidth <= 1920
            ? 'L'
            : window.innerWidth > 768 && window.innerWidth <= 1280
              ? 'M'
              : 'S';

      let actualSpan = '12';
      controlSpan?.split(' ').forEach((span) => {
        if (span.indexOf(currentSize) === 0) {
          const s = span.indexOf(currentSize) + currentSize.length;          
          actualSpan= span.substring(s);
        }
      });

      const input = document.getElementById(id);
      if (input) {
        input.setAttribute('style', 'flex-grow: 1;');

        const formItem = input.parentElement;
        const currentInputStyle = formItem?.getAttribute('style') || '';
        const iSpan = currentInputStyle.indexOf('grid-column-end: span ');
        if (iSpan === -1) {
          formItem?.setAttribute(
            'style',
            currentInputStyle + ' grid-column-end: span ' + actualSpan + ';'
          );
        }
        else {          
          formItem?.setAttribute(
            'style',
            currentInputStyle.substring(0, iSpan) + ' grid-column-end: span ' + actualSpan + ';'
          );
        }        
      }
    };
    adjustSpan();

    Device.attachResizeHandler(adjustSpan);
    // eslint-disable-next-line
  }, []);
}
