import { useHistory, useParams } from 'react-router';
import {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { toast as toastify, TypeOptions } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { debounce } from 'lodash';

// import { useSupabase } from 'utils/supabase';
import { LanguageContext } from 'components/Language/Context';
import { APIContext } from 'components/API/Context';
import config from '../config';
import { authState } from './atoms';
import { Tuple } from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';
import { useApi } from './api';

let _expires = false;

export function useSessionExpiry(doLogout: Function) {
  const { toast } = useToast();
  const expires: any = useRef();

  const expireLogin = useCallback(async () => {
    try {
      _expires = true;
      const loginTime: any = localStorage.getItem('login_time');
      if (!loginTime) return;
      const toTime = parseInt(loginTime) + 36000 * 1000;
      const auth: any = localStorage.getItem('auth');
      const store = JSON.parse(auth || '{}');
      const session: any = undefined; // await userFetch(store?.user?.email);
      let invalidSession =
        session?.data?.sessiontoken !== store.token ? 'invalidSession' : null;
      if (Date.now() > toTime || invalidSession) {
        doLogout().then((error: any) => {
          if (!error) {
            toast(invalidSession || 'sessionExpired', 'warning');
          }
        });
      } else {
        expires.current = setTimeout(expireLogin, 1000 * 30);
        console.log('checked session expiry....', {
          to: toTime,
          now: Date.now(),
        });
      }
    } catch (error) {
      console.log('ERROR expire session :::::', error);
    }
  }, [doLogout, toast]);

  useEffect(() => {
    if (!_expires) expireLogin();
    return () => {
      expires?.current && clearTimeout(expires.current);
    };
    /* eslint-disable-next-line */
  }, [expires]);

  return {
    expireLogin,
  };
}

export function useToast(): {
  toast: (msg: string, type: TypeOptions, values?: any) => void;
} {
  const [msgs, setMsgs] = useState<string[]>([]);

  const toast = (msg: string, type: TypeOptions, values?: any) => {
    if (msgs.indexOf(msg) >= 0) return;
    const m = msgs.slice();
    m.push(msg);
    setMsgs(m);
    toastify(<FormattedMessage values={values} id={msg} />, { type });
    if (process.env.NODE_ENV === 'test') return;
    setTimeout(() => {
      const m = msgs.slice();
      const index = m.findIndex((m) => m === msg);
      m.splice(index, 1);
      setMsgs(m);
    }, 5000);
  };
  return { toast };
}

export function useLanguage(languages: any) {
  const applyDesc = (data: any) => {
    return data?.map((oem) => {
      return {
        ...oem,
        languagedescription: languages?.find(
          (l) => l.languageid === oem.languageid
        )?.languagedescription,
      };
    }) as any;
  };

  return {
    applyDesc,
  };
}

export function useLanguages() {
  const {
    messages,
    languageId,
    locales: languages,
  } = useContext(LanguageContext);

  const langCb = (text: string) => messages[text] || text;

  return { messages, languageId, languages, langCb };
}

// polling hook
// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay, clear) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback?.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      if (clear) clearInterval(id);
      return () => clearInterval(id);
    }
  }, [delay, clear]);
}

export function useWebSocket(getNotificationsNew) {
  const ws = useRef<WebSocket | null>(null);
  const wssUrl =
    'wss://sap-dev.ineosautomotive.com/sap/bc/apc/iwngw/notification_push_apc';
  const wssProtocols = ['v10.pcp.sap.com'];

  useEffect(() => {
    ws.current = new WebSocket(wssUrl, wssProtocols);
    ws.current.onopen = () => console.log('ws opened for');
    ws.current.onclose = () => console.log('ws closed');

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      getNotificationsNew();
    };
  });
}

export function useIsAuthorised(object, mode, authorisations) {
  const toSentenceCase = (s) => {
    return s
      ? s.substring(0, 1).toUpperCase() + s.substring(1, s.length).toLowerCase()
      : '';
  };

  let m = mode === 'list' ? 'Get' : toSentenceCase(mode);
  const authField = 'SalesCockpit' + object + m;
  return mode === 'view' || authorisations?.[authField];
}

export function useDimensions(element?: any) {
  const [hgt, setHgt] = useState<number>(element || window.innerHeight);
  const [wdt, setWdt] = useState<number>(element || window.innerWidth);

  useEffect(() => {
    const resized = () => {
      setHgt(element || window.innerHeight);
      setWdt(element || window.innerWidth);
    };

    setTimeout(resized, 1000);
    setTimeout(resized, 3000);
    setTimeout(resized, 5000);

    if (element) {
      const ob: ResizeObserver = new ResizeObserver(() => resized);
      ob.observe(element);

      return () => {
        ob.disconnect();
      };
    } else {
      window.addEventListener('resize', resized);
      return () => {
        window.removeEventListener('resize', resized);
      };
    }
    /* eslint-disable-next-line */
  }, []);

  return { hgt, wdt };
}

export function useVisibleRows(defaultBreakpoints: any): {
  visibleRows: number;
  setBreakpoints: Function;
} {
  const { hgt, wdt } = useDimensions();
  const [breakpoints, setBreakpoints] = useState<any>(defaultBreakpoints);
  const { _status } = useApi();

  const resize = useCallback(() => {
    const c = document.getElementById('x-content');
    if (c && hgt && wdt) {
      let padding = 0;
      Object.keys(breakpoints)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .forEach((brk) => {
          if (window.innerWidth > parseInt(brk)) {
            padding = breakpoints[brk];
            // console.log(padding, brk);
          }
        });
      const space = c.clientHeight - padding;
      return space / 60;
    } else {
      return 10;
    }
  }, [hgt, wdt, breakpoints]);

  // const visibleRows = useMemo(resize, [resize]);

  const dbResize = useMemo(
    () =>
      debounce(() => {
        resize();
      }, 1000),
    [resize]
  );

  if (Object.values(_status).filter((x) => x === 'loading').length === 0) {
    dbResize();
  }

  return { visibleRows: resize(), setBreakpoints };
}

export function useDynamicHeight(padding: number): {
  adjustHeight: number;
} {
  const { hgt, wdt } = useDimensions();
  const { _status } = useApi();

  const resize = useCallback(() => {
    const c = document.getElementById('x-content');
    if (c && hgt && wdt) {
      const h = c.clientHeight - padding;
      return h > 300 ? h : 300;
    } else {
      return 10;
    }
  }, [hgt, wdt, padding]);

  // const visibleRows = useMemo(resize, [resize]);

  const dbResize = useMemo(
    () =>
      debounce(() => {
        resize();
      }, 1000),
    [resize]
  );

  if (Object.values(_status).filter((x) => x === 'loading').length === 0) {
    dbResize();
  }

  return { adjustHeight: resize() };
}

export function useDynamicWidth(
  watch: string,
  cb: Function,
  watching?: any
): number {
  const [width, setWidth] = useState<number>(500);
  const { wdt } = useDimensions(document.getElementById(watch));

  useEffect(() => {
    console.log('width:: ', wdt);
    setWidth(cb());
    /* eslint-disable-next-line */
  }, [wdt, watching]);

  return width;
}
